const SUPPORT_EMAIL = import.meta.env.VITE_APP_SUPPORT_EMAIL;

export const SupportEmail = () => {
  const link: string = encodeURI(`mailto:${SUPPORT_EMAIL}`);
  return (
    <a
      href={link}
      className="font-semibold text-[#4344dc] underline"
    >
      {SUPPORT_EMAIL}
    </a>
  );
};
