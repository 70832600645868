import { SVGAttributes } from 'react';

export default function AngleLeftIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      {...props}
    >
      <path
        d="M5.77539 8.52002C5.4082 8.88721 5.4082 9.48096 5.77539 9.84424L12.0254 16.0981C12.3926 16.4653 12.9863 16.4653 13.3496 16.0981C13.7129 15.731 13.7168 15.1372 13.3496 14.7739L7.76367 9.18408L13.3535 3.59815C13.7207 3.23096 13.7207 2.63721 13.3535 2.27393C12.9863 1.91065 12.3926 1.90674 12.0293 2.27393L5.77539 8.52002Z"
        fill={props.color ?? '#000'}
      />
    </svg>
  );
}
