import clsx from 'clsx';

interface Props {
  selected: boolean;
  description: string;
  classes?: string;
  onChange: () => void;
}

const AnswerText = ({ selected, description, classes, onChange }: Props) => {
  return (
    <div
      className={clsx(
        'mb-4 flex w-full cursor-pointer items-center rounded-lg border-2 border-solid p-4 hover:bg-[#f7f7f7] ',
        {
          'border-[#111] bg-[#f7f7f7]': selected,
          'border-[#DDD] bg-[#fff]': !selected,
        }
      )}
      onClick={onChange}
    >
      <div className="relative w-[32px]">
        <div
          className={clsx(
            'absolute left-[0%] top-[50%] h-[16px] w-[16px] -translate-y-[50%] rounded-full border-2 border-solid',
            {
              'border-[#4344dc]': selected,
              'border-[#DDD]': !selected,
            }
          )}
        >
          <div
            className={clsx(
              'absolute left-[50%] top-[50%] h-[8px] w-[8px] -translate-x-[50%] -translate-y-[50%] rounded-full',
              {
                'border-[#4344dc] bg-[#4344dc]': selected,
                'border-[#DDD] bg-[#ffffff]': !selected,
              }
            )}
          ></div>
        </div>
      </div>
      <div
        className={classes}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default AnswerText;
