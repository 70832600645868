import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const ExamDoneIcon = ({ color, ...props }: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_862_36359)">
        <path
          d="M15.0063 21.0063C14.475 21.6438 13.525 21.6438 12.9375 21.0063L9.4375 17.5063C8.85625 16.975 8.85625 16.025 9.4375 15.4375C10.025 14.8562 10.975 14.8562 11.5063 15.4375L14 17.8813L19.9375 11.9375C20.525 11.3562 21.475 11.3562 22.0063 11.9375C22.6438 12.525 22.6438 13.475 22.0063 14.0063L15.0063 21.0063ZM21.5063 2.71312C23.5625 2.44375 25.725 3.09938 27.3125 4.68625C28.9 6.275 29.5563 8.38125 29.2875 10.4937C30.8813 11.7625 32 13.7562 32 16C32 18.2438 30.8813 20.2375 29.2875 21.5063C29.5563 23.5625 28.9 25.725 27.3125 27.3125C25.725 28.9 23.5625 29.5563 21.5063 29.2875C20.2375 30.8813 18.2438 32 16 32C13.7562 32 11.7625 30.8813 10.4937 29.2875C8.38125 29.5563 6.275 28.9 4.68625 27.3125C3.1 25.725 2.44375 23.5625 2.71312 21.5063C1.065 20.2375 0 18.2438 0 16C0 13.7562 1.065 11.7625 2.71375 10.4937C2.44375 8.38125 3.1 6.275 4.68625 4.68625C6.275 3.1 8.38125 2.44375 10.4937 2.71312C11.7625 1.065 13.7562 0 16 0C18.2438 0 20.2375 1.065 21.5063 2.71312ZM11.8813 6.19187L10.75 5.82812C9.4 5.4125 7.81875 5.74188 6.80625 6.80625C5.74188 7.81875 5.4125 9.4 5.82812 10.75L6.19187 11.8813L5.09688 12.5188C3.84625 13.1813 3 14.4937 3 16C3 17.5063 3.84625 18.8188 5.09688 19.4813L6.19187 20.0625L5.82812 21.1938C5.4125 22.6 5.74188 24.125 6.80625 25.1938C7.81875 26.2563 9.4 26.5875 10.75 26.1688L11.8813 25.8062L12.5188 26.9062C13.1813 28.1562 14.4937 29 16 29C17.5063 29 18.8188 28.1562 19.4813 26.9062L20.0625 25.8062L21.1938 26.175C22.6 26.5875 24.125 26.2563 25.1938 25.1938C26.2563 24.125 26.5875 22.6 26.1688 21.1938L25.8062 20.0625L26.9062 19.4813C28.1562 18.8188 29 17.5063 29 16C29 14.4937 28.1562 13.1813 26.9062 12.5188L25.8062 11.8813L26.1688 10.75C26.5875 9.4 26.2563 7.81875 25.1938 6.80625C24.125 5.74188 22.6 5.4125 21.1938 5.82812L20.0625 6.19187L19.4813 5.09688C18.8188 3.84625 17.5063 3 16 3C14.4937 3 13.1813 3.84625 12.5188 5.09688L11.8813 6.19187Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_862_36359">
          <rect
            width="32"
            height="32"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExamDoneIcon;
