import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HeadingText from '../../shared/components/HeadingText/HeadingText';
import { actions as examActions } from '../../store/exam/reducer';
import { useAppDispatch } from '../../store/store';

const NotFound = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (!timeout) {
      timeout = setTimeout(() => {
        dispatch(examActions.DISABLE_LOADING());
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <div className="m-[auto] flex h-full w-full items-center justify-center">
      <div
        className={clsx(
          'flex h-full w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff] px-4',
          'lg:max-h-[580px] lg:shadow-cust-1'
        )}
      >
        <div className="mb-4 flex items-center justify-center bg-gradient-to-b from-[#896efd] to-[#4f28f8] bg-clip-text text-4xl font-bold text-transparent">
          404
        </div>
        <div className="max-w-[450px] text-center">
          <HeadingText className="mb-2 font-bold">{t('notFound.title')}</HeadingText>
          <HeadingText variant="secondary">{t('notFound.subtitle')}</HeadingText>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
