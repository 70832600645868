import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/components/Button/Button';
import AngleRightIcon from '../../shared/components/Icons/AngleRightIcon';

interface Props {
  disabled: boolean;
  onSubmit: () => void;
}

const Footer = ({ disabled, onSubmit }: Props) => {
  // Hooks
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'border-top fixed bottom-0 left-0 right-0 border-t-[1px] border-[#DDDDDD] bg-white p-4',
        'sm:static sm:border-0 sm:bg-transparent sm:py-0'
      )}
    >
      <Button
        className="default alt !rounded-lg"
        disabled={disabled}
        onClick={onSubmit}
      >
        <span>{t('askPermissions.submit.label')}</span>
        <AngleRightIcon
          className="ml-1"
          color="white"
        />
      </Button>
    </div>
  );
};

export default Footer;
