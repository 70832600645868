import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSocketInstance } from '../../../App';
import { FilterQuestionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import AngleLeftIcon from '../../../shared/components/Icons/AngleLeftIcon';
import AngleRightIcon from '../../../shared/components/Icons/AngleRightIcon';
import InfoIcon from '../../../shared/components/Icons/InfoIcon';
import { actions as examActions } from '../../../store/exam/reducer';
import { getCurrentFilterQuestionCounters, getCurrentStep } from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import AnswerText from '../../Questionnaire/components/AnswerText';
import QuestionText from '../../Questionnaire/components/QuestionText';

const FilterQuestion = () => {
  // Hooks
  const { t } = useTranslation();
  const { socket } = useSocketInstance();
  const dispatch = useAppDispatch();

  // Selectors
  const currentStep = useAppSelector(getCurrentStep) as FilterQuestionStep | null;
  const counters = useAppSelector(getCurrentFilterQuestionCounters);
  // States
  const [selectedAnswer, setSelectedAnswer] = useState<number>(
    () => (currentStep && currentStep.user_data && currentStep.user_data.answer_id) || 0
  );

  const progress = counters && (counters.current / counters.total) * 100;
  // Handlers
  const selectAnswerHandler = (id: number) => () => {
    setSelectedAnswer(id);
  };

  const goToPreviousStepHandler = (): void => {
    dispatch(examActions.GET_PREVIOUS_STEP());
    dispatch(uiActions.FILTER_QUESTIONS_SUBMITTED());
  };

  const goToNextStepHandler = (): void => {
    socket.emit('filter-question-submitted', {
      filter_question_id: currentStep?.data.question.id,
      filter_answer_id: selectedAnswer,
    });
    dispatch(uiActions.FILTER_QUESTIONS_SUBMITTED());
    dispatch(examActions.SET_STEP_COMPLETED());
    if (counters && counters.current + 1 === counters.total) {
      dispatch(examActions.ENABLE_LOADING());
    } else {
      dispatch(examActions.STORE_FILTER_QUESTION_ANSWER({ answer_id: selectedAnswer }));
      dispatch(examActions.GET_NEXT_STEP());
    }
  };
  // Effects
  useEffect(() => {
    if (currentStep && currentStep.user_data && currentStep.user_data.answer_id) {
      setSelectedAnswer(currentStep.user_data.answer_id);
    } else {
      setSelectedAnswer(0);
    }
  }, [currentStep]);

  // Early return
  if (!currentStep || currentStep.type !== 'filter-question') {
    return null;
  }

  return (
    <div className={clsx('relative flex h-screen w-full flex-col')}>
      {/* Sticky top bar */}
      <div
        className={clsx(
          'sticky top-0 flex h-16 w-full flex-col bg-white ',
          'lg:h-auto lg:flex-row lg:items-center lg:gap-12 lg:px-6 lg:py-4'
        )}
      >
        <div className="flex flex-1 flex-col">
          <div
            className={clsx(
              'flex h-16 w-full items-center justify-start px-4 py-2',
              'lg:h-auto lg:px-0 lg:py-4'
            )}
          >
            <div className="flex-1 overflow-hidden">
              <HeadingText
                variant="primary-small"
                className="truncate text-xl"
              >
                {t('filterQuestions.title')}
              </HeadingText>
            </div>
          </div>
          {/* Progress bar */}
          <div className="relative h-[2px] w-full bg-gray-300">
            <div
              className="transition-width absolute left-0 top-0 h-full bg-blue-500 duration-500"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
        {/* Buttons visible only on desktop mode*/}
        <div className="hidden lg:flex lg:gap-6">
          <Button
            className="secondary h-10 min-w-32 px-4 py-2"
            disabled={counters?.current === 0}
            onClick={goToPreviousStepHandler}
          >
            {counters && counters.current + 1 > 1 && (
              <AngleLeftIcon
                width={16}
                className="mr-1"
              />
            )}
            {t('questionnaire.button.back')}
          </Button>
          <Button
            className="default h-10 min-w-32 px-4 py-2"
            disabled={selectedAnswer === 0}
            onClick={goToNextStepHandler}
          >
            {counters &&
              counters.current + 1 === counters.total &&
              t('questionnaire.button.complete')}
            {counters && counters.current + 1 < counters.total && t('questionnaire.button.next')}
            {counters && counters.current + 1 < counters.total && (
              <AngleRightIcon
                className="ml-1"
                width={16}
                color="#FFF"
              />
            )}
          </Button>
        </div>
      </div>

      {/* Middle container */}
      <div className={clsx('h-full overflow-y-auto bg-white p-4', 'lg:flex lg:px-6')}>
        <div className="lg:flex-1 lg:flex-col lg:p-20">
          {/* Questions counters */}
          <HeadingText
            className="mt-4 text-base font-bold lg:text-lg"
            variant="secondary"
          >
            {t('questionnaire.question.title', {
              current: counters && counters.current + 1,
              total: counters && counters.total,
            })}
          </HeadingText>

          {/* Question container */}
          <div className={clsx('pt-4', 'lg:mb-0 lg:mr-4 lg:h-full lg:flex-1 lg:overflow-y-auto')}>
            <QuestionText
              className="no-clipboard question-text text-base font-semibold text-[#101828] lg:h-full lg:text-xl"
              description={currentStep.data.question.text}
            />
          </div>
        </div>

        <div className="lg:flex-1 lg:flex-col lg:p-20">
          {/* Info Box */}
          <div className="my-5 flex gap-2 text-[#666]">
            <InfoIcon color="#666" /> <span>{t('questionnaire.infobox.text')}</span>
          </div>
          {/* Answers container */}
          <div className={clsx('lg:ml-4 lg:h-full lg:flex-1 lg:overflow-y-auto')}>
            {currentStep.data.answers.map((answer) => (
              <AnswerText
                key={`question-${currentStep.data.question.id}-answer-${answer.id}`}
                classes="flex-1 font-medium font-[#101828] question-answer overflow-x-auto"
                selected={selectedAnswer === answer.id}
                description={answer.text}
                onChange={selectAnswerHandler(answer.id)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Sticky bottom bar */}
      <div
        className={clsx(
          'sticky bottom-0 left-0 z-10 flex h-[64px] w-full items-center justify-between border-t border-solid border-[#d0d5dd] bg-white px-4 py-2',
          'lg:hidden'
        )}
      >
        <Button
          className="secondary mr-1"
          disabled={counters?.current === 0}
          onClick={goToPreviousStepHandler}
        >
          {counters && counters.current + 1 > 1 && (
            <AngleLeftIcon
              width={16}
              className="mr-1"
            />
          )}
          {t('questionnaire.button.back')}
        </Button>
        <Button
          className="default ml-1"
          disabled={selectedAnswer === 0}
          onClick={goToNextStepHandler}
        >
          {counters &&
            counters.current + 1 === counters.total &&
            t('questionnaire.button.complete')}
          {counters && counters.current + 1 < counters.total && (
            <>
              {t('questionnaire.button.next')}
              <AngleRightIcon
                className="ml-1"
                width={16}
                color="#FFF"
              />
            </>
          )}
        </Button>
      </div>
      {/* Warning box on desktop mode */}
      <div
        className={clsx(
          'hidden',
          'lg:absolute lg:bottom-6 lg:left-1/2 lg:z-10 lg:flex lg:-translate-x-1/2 lg:transform lg:gap-2 lg:rounded-lg lg:border lg:border-[#BF8700] lg:bg-[#FFF8C5] lg:p-4 lg:font-bold lg:text-[#946900]'
        )}
      >
        <InfoIcon color="#946900" />
        {t('questionnaire.warning.text')}
      </div>
    </div>
  );
};

export default FilterQuestion;
