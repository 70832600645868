import clsx from 'clsx';
import { getCompanyData, getJobPostData } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';

const CompanyDetailsHeader = () => {
  // Selectors
  const job = useAppSelector(getJobPostData);
  const company = useAppSelector(getCompanyData);

  return (
    <div className={clsx('flex p-4', 'sm:border-b-[1px] sm:border-[#E4E4E4]')}>
      <div
        className={clsx('mr-4 h-12 w-12 rounded-lg bg-contain bg-center bg-no-repeat')}
        style={{
          backgroundImage: `url(${company?.logo})`,
        }}
      />
      <div className="flex flex-1 flex-col">
        <p className="text-lg font-semibold text-[#111111]">{job?.name}</p>
        <p className="text-sm font-normal text-[#525252]">{company?.name}</p>
      </div>
    </div>
  );
};

export default CompanyDetailsHeader;
