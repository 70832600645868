import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
  bgColor?: string;
  width?: number;
  height?: number;
}

const CheckIcon = ({
  color = '#1F883D',
  bgColor = '#DAFBE1',
  width = 40,
  height = 40,
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={40}
        height={40}
        rx={20}
        fill={bgColor}
      />
      <path
        d="M20 30c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.413-1.414-5.657 5.657-2.829-2.829-1.414 1.414L19.003 24z"
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;
