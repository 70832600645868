import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BriefingStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import { actions as examActions } from '../../../store/exam/reducer';
import { getCurrentStep } from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import { getBriefingLoading } from '../../../store/ui/selectors';
import BriefingBox from './BriefingBox';

const Briefing = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Params
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  // Selectors
  const currentStep = useAppSelector(getCurrentStep) as BriefingStep | null;
  const loading = useAppSelector(getBriefingLoading);

  // Handlers
  const submitHandler = (): void => {
    dispatch(uiActions.BRIEFING_SUBMIT());
    dispatch(examActions.SET_STEP_COMPLETED());
    if (currentStep && ['soft-skills', 'custom-questions'].includes(currentStep.group)) {
      navigate(`/${job_post_alias}/${application_alias}/test-devices`, { replace: true });
    } else {
      dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
    }
  };

  // Effects
  useEffect(() => {
    dispatch(uiActions.BRIEFING_START());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentStep || currentStep.type !== 'briefing') {
    return null;
  }
  return (
    <div className="m-[auto] flex w-full max-w-5xl items-center justify-center lg:h-full">
      <div className="mb-12 flex w-full flex-col items-center justify-start bg-[#FFFFFF] lg:mt-4 lg:rounded-[8px] lg:p-6 lg:shadow-cust-1">
        <div className="my-4 flex w-full flex-col justify-start px-4 lg:px-0">
          {/* Skill title and type */}
          <HeadingText className="font-bold">
            {currentStep.group === 'filter-questions' && t('briefing.filterQuestions.title')}
            {currentStep.group === 'custom-questions' && t('briefing.customQuestions.title')}
            {(currentStep.group === 'soft-skills' || currentStep.group === 'hard-skills') &&
              currentStep.data.title}
            {currentStep.group === 'custom-questionnaires' && currentStep.data.title}
          </HeadingText>
          <HeadingText variant="secondary">
            {currentStep.group === 'soft-skills' && t('briefing.test.type.softSkill')}
            {currentStep.group === 'hard-skills' && t('briefing.test.type.hardSkill')}
            {currentStep.group === 'custom-questionnaires' &&
              t('briefing.test.type.customQuestionnaire')}
          </HeadingText>
        </div>
        {/* Row with info box widgets */}
        <BriefingBox
          type={currentStep.group}
          questions={currentStep.data.questions}
          duration={currentStep.data.duration}
          retakes={currentStep.data.attempts}
        />
        {/* Recommendations for use */}
        <div className="my-4 flex w-full flex-col justify-start px-4 lg:px-0">
          <div className="mb-2 flex flex-row items-start justify-start">
            <HeadingText variant="primary-small">{t('briefing.infoBox.title')}</HeadingText>
          </div>

          <div className="mb-4 flex flex-row items-start justify-start px-6">
            <ol className="list-decimal">
              {currentStep.data.attempts && (
                <li className="text-[#666]">
                  <span className="ml-1 flex-1 text-[#666]">
                    {t('briefing.infoBox.text1', { count: currentStep.data.attempts })}
                  </span>
                </li>
              )}
              {currentStep.group !== 'custom-questions' && (
                <li className="text-[#666]">
                  <span className="ml-1 flex-1 text-[#666]">{t('briefing.infoBox.text2')}</span>
                </li>
              )}
              {currentStep.group === 'custom-questions' && (
                <li className="text-[#666]">
                  <span className="ml-1 flex-1 text-[#666]">{t('briefing.infoBox.text4')}</span>
                </li>
              )}
              <li className="text-[#666]">
                <span className="ml-1 flex-1 text-[#666]">{t('briefing.infoBox.text3')}</span>
              </li>
            </ol>
          </div>
        </div>
        {/* Submit button */}
        <div className="fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2 lg:static lg:justify-end lg:border-0 lg:pb-0">
          <Button
            loading={loading}
            className="default"
            onClick={submitHandler}
          >
            {t('briefing.button.next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Briefing;
