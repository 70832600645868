import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../shared/components/Button/Button';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import CheckIcon from '../../../shared/components/Icons/CheckIcon';
import ThumbsUpCircleGreenIcon from '../../../shared/components/Icons/ThumbsUpCircleGreenIcon';
import { actions as examActions } from '../../../store/exam/reducer';
import {
  checkAllStepsCompleted,
  getCurrentFilterQuestionCounters,
  getCurrentStep,
  getKillerQuestionsFailedDetails,
  getLoading,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';

const FilterQuestionDone = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  const loading = useAppSelector(getLoading);
  const killerQuestionsDetails = useAppSelector(getKillerQuestionsFailedDetails);
  const currentStep = useAppSelector(getCurrentStep);
  const counters = useAppSelector(getCurrentFilterQuestionCounters);
  const allStepsCompleted = useAppSelector(checkAllStepsCompleted);

  const submitHandler = (): void => {
    dispatch(uiActions.FILTER_QUESTIONS_SUBMITTED());
    // Check if there is nothing to do after the filter questions. Usually when we reset a single step and the user has already completed all the steps.
    if (counters && counters.current + 1 === counters.total) {
      if (allStepsCompleted) {
        navigate(`/${job_post_alias}/${application_alias}/already-done`, { replace: true });
      } else {
        dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
      }
    }
  };

  useEffect(() => {
    dispatch(uiActions.FILTER_QUESTIONS_SUBMIT());
  }, [dispatch]);

  if (!currentStep && !killerQuestionsDetails) {
    return null;
  }
  return killerQuestionsDetails && !loading ? (
    <div className="m-[auto] flex h-full w-full items-center justify-center">
      <div
        className={clsx(
          'flex h-full w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff] px-4',
          'lg:max-h-[580px] lg:shadow-cust-1'
        )}
      >
        <div className="mb-6 flex h-[80px] w-[80px] items-center justify-center rounded-full">
          <CheckIcon
            color="#13ad50"
            width={80}
            height={80}
          />
        </div>
        <div className="max-w-[450px] text-center">
          <HeadingText className="mb-2 font-bold">{killerQuestionsDetails.title}</HeadingText>
          <HeadingText variant="secondary">{killerQuestionsDetails.description}</HeadingText>
        </div>
      </div>
    </div>
  ) : (
    <div className="m-[auto] flex h-full w-full max-w-5xl items-center justify-center">
      <div
        className={clsx(
          'flex h-full w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff]',
          'lg:max-h-[580px] lg:shadow-cust-1'
        )}
      >
        <div className="mb-8 flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#1ad37a]">
          <ThumbsUpCircleGreenIcon color="white" />
        </div>
        <div className="text-center">
          <HeadingText className="font-bold">{t('filterQuestions.done.title')}</HeadingText>
        </div>
        <div
          className={clsx(
            'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
            'lg:static lg:mt-8 lg:justify-center lg:border-0 lg:pb-0'
          )}
        >
          <Button
            loading={loading}
            disabled={loading}
            className="default"
            onClick={submitHandler}
          >
            {t('filterQuestions.done.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterQuestionDone;
