import { Outlet, useOutletContext } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import PageLoader from './shared/components/PageLoader/PageLoader';
import useSocket from './shared/socket/useSocket';
import { getLoading } from './store/exam/selectors';
import { useAppSelector } from './store/store';

type ContextType = { socket: Socket; connected: boolean };

export const useSocketInstance = () => useOutletContext<ContextType>();

const App = () => {
  const { socket, connected } = useSocket();

  const loading = useAppSelector(getLoading);

  return (
    <div className="h-full">
      {loading && <PageLoader size={64} />}
      <Outlet context={{ socket, connected }} />
    </div>
  );
};

export default App;
