import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const { VITE_APP_SUPPORT_EMAIL } = import.meta.env;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex flex-col items-center bg-white py-5',
        'md:mb-10 md:bg-transparent md:py-0'
      )}
    >
      <p className="mb-2 text-sm font-normal text-[#111111]">
        {t('interviewSimulationPrompt.needHelp.text1')}
      </p>
      <p className="text-sm font-normal text-[#525252]">
        {t('interviewSimulationPrompt.needHelp.text2')}
        <a
          href={`mailto:${VITE_APP_SUPPORT_EMAIL}`}
          target="_blank"
          rel="noreferrer noopener"
          className="text-underline text-sm font-semibold text-[#0078D6]"
        >
          {VITE_APP_SUPPORT_EMAIL}
        </a>
      </p>
    </div>
  );
};

export default Footer;
