import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../shared/components/Button/Button';
import Envelope from '../../../shared/components/Icons/Envelope';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import { getJobPostData } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import { getGuestInvited } from '../../../store/ui/selectors';

export const GuestInvited = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { email, name, surname } = useAppSelector(getGuestInvited);
  const jobPost = useAppSelector(getJobPostData);
  return (
    jobPost && (
      <div className="m-[auto] flex h-full w-full max-w-[500px] items-center justify-center">
        <div
          className={clsx(
            'flex h-full w-full flex-col items-center justify-center bg-[#FFFFFF] p-4 pt-4',
            'lg:h-auto lg:rounded-[8px] lg:p-6 lg:shadow-cust-1'
          )}
        >
          <div className="flex justify-center">
            <JobHeader logoOnly={true} />
          </div>
          <Envelope className="my-6" />
          <div className="text-center">
            {t('guest.invited.heading')}{' '}
            <b>
              {name} {surname}{' '}
            </b>
            <div className="my-6">
              {t('guest.invited.email_sent')} <br />
              <b>{email}</b>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: t('guest.invited.email_info', { jobTitle: jobPost.name }),
              }}
            />
            <div className="my-6 text-sm text-[#666666]">{t('guest.invited.spam')}</div>
            <div className="flex justify-center">
              <Button
                className="default flex-1"
                onClick={(_e) => navigate(`/${jobPost.alias}`, { replace: true })}
              >
                {t('guest.invited.back')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
