import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HeadingText from '../../shared/components/HeadingText/HeadingText';
import TriangleExclamationIcon from '../../shared/components/Icons/TriangleExclamationIcon';
import { actions as examActions } from '../../store/exam/reducer';
import { useAppDispatch } from '../../store/store';

const SUPPORT_EMAIL = import.meta.env.VITE_APP_SUPPORT_EMAIL;

const MAC_PLATFORMS = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
const WINDOWS_PLATFORMS = ['Win32', 'Win64', 'Windows', 'WinCE'];
const IOS_PLATFORMS = ['iPhone', 'iPad', 'iPod'];

const getOperatingSystem = (
  platform: string,
  user_agent: string
): 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | 'Unknown' => {
  let os: 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | 'Unknown' = 'Unknown';
  if (MAC_PLATFORMS.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (IOS_PLATFORMS.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (WINDOWS_PLATFORMS.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(user_agent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
};

const TechnicalIssue = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigator: any = window.navigator;

  const platform = navigator?.userAgentData?.platform || navigator.platform;

  const user_agent = navigator.userAgent;

  const operating_system: 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | 'Unknown' =
    getOperatingSystem(platform, user_agent);

  const link: string =
    encodeURI(`mailto:${SUPPORT_EMAIL}?subject=Problema tecnico&body=Descrizione problema riscontrato: <Descrivi quanto più accuratamente possibile la problematica riscontrata>
    
--
Informazioni di debug (si prega di non modificarle e/o cancellarle):

User Agent: ${user_agent}

Sistema Operativo: ${operating_system}`);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (!timeout) {
      timeout = setTimeout(() => {
        dispatch(examActions.DISABLE_LOADING());
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <div className="m-[auto] flex h-full w-full items-center justify-center">
      <div
        className={clsx(
          'flex h-full w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff] px-4',
          'lg:max-h-[580px] lg:shadow-cust-1'
        )}
      >
        <div className="mb-6 flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#FFF8EB]">
          <TriangleExclamationIcon color="#b37800" />
        </div>
        <div className="max-w-[450px] text-center">
          <HeadingText className="mb-4 font-bold">{t('technicalIssue.title')}</HeadingText>
          <HeadingText
            className="mb-8"
            variant="secondary"
          >
            {t('technicalIssue.subtitle')}
          </HeadingText>
          <HeadingText variant="secondary">
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-[#4344dc] underline"
            >
              {t('technicalIssue.link')}
            </a>
          </HeadingText>
        </div>
      </div>
    </div>
  );
};

export default TechnicalIssue;
