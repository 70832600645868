import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../shared/components/Button/Button';
import Card from '../../shared/components/Card/Card';
import CompanyDetailsHeader from '../../shared/components/CompanyDetailsHeader/CompanyDetailsHeader';
import { actions as examActions } from '../../store/exam/reducer';
import { useAppDispatch } from '../../store/store';
import Footer from './Footer';

const InterviewSimulationPrompt = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  // Handlers
  const acceptSimulationHandler = () =>
    navigate(`/${job_post_alias}/${application_alias}/simulate-interview`, { replace: true });
  const rejectSimulationHandler = () => dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());

  return (
    <div className={clsx('mx-auto flex h-full w-full flex-col', 'md:max-w-[840px]')}>
      {/* Company details row */}
      <CompanyDetailsHeader />

      <div className="flex h-full flex-1 flex-col justify-center">
        <Card
          className={clsx('flex h-full w-full justify-center', 'md:max-h-[570px] md:max-w-[840px]')}
        >
          <div
            className={clsx(
              'flex h-full max-w-[480px] flex-col justify-between',
              'md:h-auto md:justify-center'
            )}
          >
            <div className={clsx('flex flex-1 flex-col justify-center px-4', 'md:flex-initial')}>
              <h3 className="mb-2 text-center text-2xl font-semibold leading-8">
                {t('interviewSimulationPrompt.title')}
              </h3>
              <p className="text-center text-base font-normal leading-6 text-[#666666]">
                {t('interviewSimulationPrompt.description')}
              </p>
            </div>
            <div
              className={clsx(
                'mt-6 flex flex-col items-center justify-center border-t border-[#DDDDDD] p-4 pb-0',
                'md:flex-row md:border-none md:p-0'
              )}
            >
              <Button
                className={clsx('secondary alt mb-4 !rounded-lg', 'md:mb-0 md:mr-6')}
                onClick={acceptSimulationHandler}
              >
                {t('interviewSimulationPrompt.buttons.accept')}
              </Button>
              <Button
                className="default alt !rounded-lg"
                onClick={rejectSimulationHandler}
              >
                {t('interviewSimulationPrompt.buttons.reject')}
              </Button>
            </div>
          </div>
        </Card>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default InterviewSimulationPrompt;
