import { SVGAttributes } from 'react';

export default function AngleRightIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M12.8535 8.52002C13.2207 8.88721 13.2207 9.48096 12.8535 9.84424L6.60352 16.0981C6.23633 16.4653 5.64258 16.4653 5.2793 16.0981C4.91602 15.731 4.91211 15.1372 5.2793 14.7739L10.8652 9.18799L5.27539 3.59815C4.9082 3.23096 4.9082 2.63721 5.27539 2.27393C5.64258 1.91065 6.23633 1.90674 6.59961 2.27393L12.8535 8.52002Z"
        fill={props.color ?? '#000'}
      />
    </svg>
  );
}
