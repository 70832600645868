import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import { SoftSkillQuestionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import ArrowRotateLeftIcon from '../../../shared/components/Icons/ArrowRotateLeftIcon';
import CheckmarkIcon from '../../../shared/components/Icons/CheckmarkIcon';
import InfoIcon from '../../../shared/components/Icons/InfoIcon';
import PlayLittleIcon from '../../../shared/components/Icons/PlayLittleIcon';
import { actions as examActions } from '../../../store/exam/reducer';
import {
  getCurrentSoftSkillQuestionCounters,
  getCurrentStep,
  getSoftSkillQuestionRetakeDetails,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';

export default function VideoPreview() {
  // Hooks
  const { socket, connected } = useSocketInstance();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Selectors
  const currentStep = useAppSelector(getCurrentStep) as SoftSkillQuestionStep | null;
  const counters = useAppSelector(getCurrentSoftSkillQuestionCounters);
  const retakeDetails = useAppSelector(getSoftSkillQuestionRetakeDetails);
  // Early failure for undefined interview
  if (!currentStep) {
    // TODO: Add a better component
    return <div className="flex h-40 items-center">Something went wrong</div>;
  }

  // Handlers
  const submitVideoInterview = (): void => {
    socket.emit('submit-soft-skill-question-interview', {
      job_post_alias,
      application_alias,
      question_id: currentStep.data.question.id,
    });
    navigate(`/${job_post_alias}/${application_alias}/soft-skill-interview-done`, {
      replace: true,
    });
  };

  const interviewRetake = (): void => {
    dispatch(examActions.INCREASE_RETAKE_COUNT());
    navigate(`/${job_post_alias}/${application_alias}/soft-skill-interview`, { replace: true });
  };

  return (
    <div className="m-[auto] flex h-full w-full max-w-3xl flex-col items-center justify-center">
      <div
        className={clsx(
          'm-[0 auto] relative flex h-full w-full flex-col items-center gap-6 overflow-hidden bg-white p-6',
          'lg:h-auto lg:max-w-[1024px] lg:rounded-lg lg:shadow-cust-1'
        )}
      >
        <div className="flex w-full items-center justify-between">
          {/* Question counter */}
          <div className="text-lg font-bold">
            {t('videoInterview.counter.question')} {counters && counters.current + 1}{' '}
            {t('videoInterview.counter.of')} {counters && counters.total}
          </div>
          <div className="flex items-center gap-2">
            {/* Connection icon */}
            <ConnectionStatus
              type="icon"
              connected={connected}
            />
            <span className={clsx('hidden text-sm text-[#666666]', 'lg:block')}>
              {connected
                ? t('videoPreview.connectionIcon.connected')
                : t('videoPreview.connectionIcon.disconnected')}
            </span>
          </div>
        </div>
        {/* Video Player */}
        <video
          className="h-auto w-[40rem] rounded-lg"
          src={retakeDetails?.temporary_url}
          controls
        />
        {/* Retake info banner */}
        <span className="flex w-full justify-center gap-2 rounded-lg bg-[#FFF8C5] px-2 py-1 text-[#AD4E00]">
          <InfoIcon color="#AD4E00" />
          {t('videoPreview.attempt.banner', {
            count:
              (currentStep && currentStep.data.retake && currentStep.data.retake.current + 1) || 0,
          })}
        </span>
        {/* Footer */}
        <div
          className={clsx(
            'absolute bottom-0 flex w-full flex-col items-center justify-between',
            'lg:relative lg:flex-row'
          )}
        >
          <div className="mb-4 flex items-center justify-center gap-2 lg:mb-0">
            <PlayLittleIcon color="#1F883D" />
            <span className="text-sm text-[#1F883D]">{t('videoPreview.replay.status')}</span>
          </div>
          <div className={clsx('flex w-full flex-col items-center gap-4', 'lg:w-auto lg:flex-row')}>
            <Button
              className="secondary gap-2"
              onClick={interviewRetake}
            >
              <ArrowRotateLeftIcon color="#111" />
              {t('videoPreview.button.retry')}
            </Button>
            <Button
              className="mb-6 gap-2 bg-[#1F883D] lg:mb-0"
              onClick={submitVideoInterview}
            >
              <CheckmarkIcon color="#FFF" />
              {t('videoPreview.button.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
