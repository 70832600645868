import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import ApplicationAlias from './features/ApplicationAlias/ApplicationAlias';
import AskPermissions from './features/AskPermissions/AskPermissions';
import Briefing from './features/Briefing/components/Briefing';
import CustomQuestionnaire from './features/CustomQuestionnaire/components/CustomQuestionnaire';
import CustomQuestionnaireDone from './features/CustomQuestionnaire/components/CustomQuestionnaireDone';
import ExamDone from './features/ExamDone/ExamDone';
import ExamExpired from './features/ExamExpired/ExamExpired';
import FilterQuestion from './features/FilterQuestion/components/FilterQuestion';
import FilterQuestionDone from './features/FilterQuestion/components/FilterQuestionDone';
import Guest from './features/Guest/components/Guest';
import { GuestInvited } from './features/Guest/components/GuestInvited';
import InterviewSimulation from './features/InterviewSimulation/InterviewSimulation';
import InterviewSimulationPrompt from './features/InterviewSimulation/InterviewSimulationPrompt';
import Intro from './features/Intro/components/Intro';
import NotFound from './features/NotFound/NotFound';
import PresentationInterview from './features/PresentationInterview/components/PresentationInterview';
import PresentationInterviewDone from './features/PresentationInterview/components/PresentationInterviewDone';
import Questionnaire from './features/Questionnaire/components/Questionnaire';
import QuestionnaireDone from './features/Questionnaire/components/QuestionnaireDone';
import Summary from './features/Summary/components/Summary';
import TechnicalIssue from './features/TechnicalIssue/TechnicalIssue';
import VideoInterview from './features/VideoInterview/components/VideoInterview';
import VideoInterviewDone from './features/VideoInterview/components/VideoInterviewDone';
import VideoPreview from './features/VideoInterview/components/VideoPreview';

const routes: ReturnType<typeof createBrowserRouter> = createBrowserRouter([
  {
    path: '/:job_post_alias',
    element: <App />,
    children: [
      {
        index: true,
        element: <Guest />,
      },
      {
        path: 'invited',
        element: <GuestInvited />,
      },
      {
        path: 'not-found',
        element: <NotFound />,
      },
      {
        path: 'technical-issue',
        element: <TechnicalIssue />,
      },
      {
        path: ':application_alias',
        element: <ApplicationAlias />,
        children: [
          {
            path: 'intro',
            element: <Intro />,
          },
          {
            path: 'summary',
            element: <Summary />,
          },
          {
            path: 'briefing',
            element: <Briefing />,
          },
          {
            path: 'test-devices',
            element: <AskPermissions />,
          },
          {
            path: 'simulate-interview-prompt',
            element: <InterviewSimulationPrompt />,
          },
          {
            path: 'simulate-interview',
            element: <InterviewSimulation />,
          },
          {
            path: 'filter-questions',
            element: <FilterQuestion />,
          },
          {
            path: 'filter-questions-done',
            element: <FilterQuestionDone />,
          },
          {
            path: 'custom-interview',
            element: <PresentationInterview />, //TODO: Change PresentationInterview component into a generic one for interviews
          },
          {
            path: 'custom-interview-done',
            element: <PresentationInterviewDone />, //TODO: Change PresentationInterviewDone component into a generic one for interviews
          },
          {
            path: 'soft-skill-interview',
            element: <VideoInterview />,
          },
          {
            path: 'soft-skill-interview-preview',
            element: <VideoPreview />,
          },
          {
            path: 'soft-skill-interview-done',
            element: <VideoInterviewDone />,
          },
          {
            path: 'hard-skill-questionnaire',
            element: <Questionnaire />,
          },
          {
            path: 'hard-skill-questionnaire-done',
            element: <QuestionnaireDone />,
          },
          {
            path: 'custom-questionnaire',
            element: <CustomQuestionnaire />,
          },
          {
            path: 'custom-questionnaire-done',
            element: <CustomQuestionnaireDone />,
          },
          {
            path: 'not-found',
            element: <NotFound />,
          },
          {
            path: 'expired',
            element: <ExamExpired />,
          },
          {
            path: 'already-done',
            element: <ExamDone />,
          },
          {
            path: 'technical-issue',
            element: <TechnicalIssue />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default routes;
