import clsx from 'clsx';
import { HTMLAttributes, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {}

const Card = ({ children, ...props }: Props) => {
  return (
    <div
      className={clsx(
        'shadow-card mx-auto flex h-full w-full bg-white',
        'md:rounded-2xl md:p-6',
        props.className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
