import { HTMLAttributes } from 'react';

const Envelope = ({ ...props }: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      width="80"
      height="81"
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group 1832">
        <path
          id="Rectangle 2716"
          d="M0 31.741C0 29.0818 1.32127 26.5965 3.52559 25.1092L35.5256 3.51888C38.2296 1.6945 41.7704 1.6945 44.4744 3.51888L76.4744 25.1092C78.6787 26.5965 80 29.0818 80 31.741V72.5C80 76.9183 76.4183 80.5 72 80.5H8C3.58172 80.5 0 76.9183 0 72.5V31.741Z"
          fill="url(#paint0_linear_574_1418)"
        />
        <g id="Group 1833">
          <rect
            id="Rectangle 2717"
            x="7"
            y="6.5"
            width="66"
            height="70"
            rx="10"
            fill="#F0F8FF"
            stroke="url(#paint1_linear_574_1418)"
            strokeWidth="0.54"
          />
          <rect
            id="Rectangle 2718"
            x="23"
            y="30.5"
            width="34"
            height="5"
            rx="2.5"
            fill="#B8D2E9"
          />
          <rect
            id="Rectangle 2719"
            x="23"
            y="17.5"
            width="34"
            height="5"
            rx="2.5"
            fill="#B8D2E9"
          />
        </g>
        <path
          id="Vector 2"
          d="M0 31.5V72.5C0 76.9183 3.58172 80.5 8 80.5H72C76.4183 80.5 80 76.9183 80 72.5V63.8585L0 31.5Z"
          fill="url(#paint2_linear_574_1418)"
        />
        <path
          id="Vector 1"
          d="M80 31.5V72.5C80 76.9183 76.4183 80.5 72 80.5H8C3.58172 80.5 0 76.9183 0 72.5V66.5554C0 64.9254 0.989064 63.4584 2.50013 62.8472L80 31.5Z"
          fill="url(#paint3_linear_574_1418)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_574_1418"
          x1="2.05128"
          y1="35.6807"
          x2="77.0752"
          y2="30.4786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1587DA" />
          <stop
            offset="1"
            stopColor="#1F21DC"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_574_1418"
          x1="40.6526"
          y1="-7.49212"
          x2="38.1565"
          y2="78.3868"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop
            offset="1"
            stopColor="white"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_574_1418"
          x1="34"
          y1="59.5"
          x2="7.02064"
          y2="38.6333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B6CFF" />
          <stop
            offset="1"
            stopColor="#7FC0FB"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_574_1418"
          x1="18.9561"
          y1="62.4421"
          x2="71.1442"
          y2="33.1455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FC0FB" />
          <stop
            offset="1"
            stopColor="#6B6CFF"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Envelope;
