import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportEmail } from '../../../shared/components/SupportEmail/SupportEmail';
import { actions as examActions } from '../../../store/exam/reducer';
import { useAppDispatch } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import SummaryBox from './SummaryBox';

const Summary = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Handlers
  const submitHandler = (): void => {
    dispatch(uiActions.SUMMARY_SUBMIT());
    dispatch(examActions.SET_STEP_COMPLETED());
    dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
  };

  // Effects
  useEffect(() => {
    dispatch(uiActions.SUMMARY_START());
  }, [dispatch]);

  return (
    <div className="relative m-[auto] flex h-[90%] w-full max-w-5xl flex-col items-center justify-center lg:h-full">
      <SummaryBox onSubmit={submitHandler} />
      <div className="absolute bottom-8">
        {t('contact.us')} <SupportEmail />
      </div>
    </div>
  );
};

export default Summary;
