import clsx from 'clsx';
import { ChangeEvent, useRef } from 'react';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

const Checkbox = ({ name, label, disabled = false, onChange }: Props) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>): void => onChange(e.target.checked);

  return (
    <label
      className={clsx(
        'mb-2 flex items-start justify-start text-base font-normal leading-[18px] text-[#101828]',
        { 'cursor-not-allowed opacity-50': disabled }
      )}
      htmlFor={name}
    >
      <div className="relative mr-2 h-[18px] w-[18px] overflow-hidden rounded border border-solid border-[#c6c6c6]">
        {ref.current && ref.current.checked && (
          <svg
            className="absolute left-[3px] top-[4px] z-20"
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66602 3.66683L4.2057 6.1335L8.33268 1.3335"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        <input
          ref={ref}
          id={name}
          name={name}
          disabled={disabled}
          type="checkbox"
          className="absolute left-0 top-0 z-10 h-full w-full appearance-none checked:bg-[#4344DC] indeterminate:bg-gray-300"
          onChange={!disabled ? changeHandler : () => null}
        />
      </div>
      <div
        className="checkbox-with-link flex-1"
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      />
    </label>
  );
};

export default Checkbox;
