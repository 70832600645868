import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props {
  version: 'mobile' | 'desktop';
}

const CLASSES = {
  mobile: 'flex w-full flex-col justify-center bg-[#4B4DF7] p-4 lg:hidden',
  desktop:
    'absolute -top-[100px] -z-[1] hidden min-h-[140px] w-full flex-col justify-start rounded-lg bg-[#4B4DF7] p-4 shadow-card lg:flex',
};

const TestBanner = ({ version }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={CLASSES[version]}>
      <p
        className={clsx('text-center text-lg font-bold leading-7 text-white', {
          'mt-2': version === 'desktop',
        })}
      >
        {t('interviewSimulation.banner.title')}
      </p>
      <p className="text-center font-normal text-white">
        {t('interviewSimulation.banner.subtitle')}
      </p>
    </div>
  );
};

export default TestBanner;
