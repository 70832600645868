import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import Button from '../../../shared/components/Button/Button';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import ThumbsUpCircleGreenIcon from '../../../shared/components/Icons/ThumbsUpCircleGreenIcon';
import { actions as examActions } from '../../../store/exam/reducer';
import { checkAllStepsCompleted } from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import { getQuestionnaireLoading } from '../../../store/ui/selectors';

const QuestionnaireDone = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const { socket } = useSocketInstance();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(getQuestionnaireLoading);
  const allStepsCompleted = useAppSelector(checkAllStepsCompleted);

  const submitHandler = (): void => {
    dispatch(uiActions.QUESTIONNAIRE_SUBMITTED());
    socket.emit('questionnaire-submitted');
    // Check if there is nothing to do after this hard skill
    if (allStepsCompleted) {
      navigate(`/${job_post_alias}/${application_alias}/already-done`, { replace: true });
    } else {
      dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
    }
  };

  useEffect(() => {
    dispatch(uiActions.QUESTIONNAIRE_SUBMIT());
  }, [dispatch]);

  return (
    <div className="m-[auto] flex h-full w-full items-center justify-center">
      <div
        className={clsx(
          'flex h-full w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff] px-4',
          'lg:max-h-[580px] lg:shadow-cust-1'
        )}
      >
        <div className="mb-8 flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#1ad37a]">
          <ThumbsUpCircleGreenIcon color="white" />
        </div>
        <div className="text-center lg:max-w-[450px]">
          <HeadingText className="mb-2 font-bold">{t('questionnaire.done.title')}</HeadingText>
          <HeadingText variant="secondary">{t('questionnaire.done.subtitle')}</HeadingText>
        </div>
        <div
          className={clsx(
            'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
            'lg:static lg:mt-8 lg:justify-center lg:border-0 lg:pb-0'
          )}
        >
          <Button
            loading={loading}
            disabled={loading}
            className="default"
            onClick={submitHandler}
          >
            {t('questionnaire.done.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireDone;
